import React from 'react'


const TitleCard = ({
    title,
    icons,
    backgroundColor,
    fontColor,
}) => {

    return (
        <div className={`container_header 
         ${backgroundColor}_color`}>
            <span className={`${fontColor}_paragraph_text`}>{title}</span>
            <div className='container_header_emojis'>
                { icons.map((icon, index) => (
                    <span key={index}>{icon ? String.fromCodePoint(icon) : null}</span>
                ))}
            </div>
        </div>
    )
}

export default TitleCard
