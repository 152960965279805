/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"

//Axios
import axios from "../../axios"

//Redux
import { useSelector } from "react-redux"

//Components
// import JobOpening from "../Tools/JobOpening/JobOpening";
import InputArea from "../../components/Tools/InputArea/InputArea"
// import DragAndDrop from "../Tools/DragAndDrop/DragAndDrop";
import TitleCard from "../../components/Tools/TitleCard/TitleCard"
import Layout from "../../components/Layout"

// import Button from 'react-bootstrap/Button'

//Illustration
import noOpenPosition from "../../assets/company/no-open-position.svg"

//Icon
// import deleteIconOutline from "../../assets/icons/delete-icon-outline.svg";
import circleCheckIcon from "../../assets/company/circle-check-light-black.svg"

//Localized
import buttonData from "../../localized/buttonsLocalized.json"
import careerData from "../../localized/careersLocalized.json"
import contactData from "../../localized/contactLocalized.json"

const Careers = props => {
  //Language information
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  const [buttonDisableStatus, setButtonDisableStatus] = useState(true)

  //Userin bilgilerinin tutulacagi degiskenler
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [resumeLink, setResumeLink] = useState("")
  // const [newFile, setNewFile] = useState(null);
  // const [newFileUrl, setNewFileUrl] = useState(null);

  //Open jobs array
  const [openJobs, setOpenJobs] = useState([])

  //Form statusune gore datanin sifirlanmasi ve farkli bir ekranin gosterilmesi
  const [successStatus, setSuccessStatus] = useState(false)

  // Icons
  const titleCardIcons = ["128075", "128187", "128640"]

  const sendCV = () => {
    if (resumeLink.includes("https")) {
      const newApplication = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phoneNumber,
        link: resumeLink,
      }

      axios
        .post("/muni-management/job-application/", newApplication)
        .then(result => {
          setSuccessStatus(true)
        })
    }
  }

  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      resumeLink !== ""
    ) {
      setButtonDisableStatus(false)
    } else {
      setButtonDisableStatus(true)
    }
  }, [firstName, lastName, email, phoneNumber, resumeLink])

  const successfullySended = (
    <div className="contact_success">
      <img
        src={circleCheckIcon}
        alt="Success Icon"
        className="contact_success_image my-3"
      />
      <div className="contact_success_content my-3">
        <span className="contact_success_content_header my-3">
          {contactData.allSet?.[currentLanguage]}!
        </span>
        <div className="contact_success_content_footer my-3">
          <span>{careerData.contactForApplication?.[currentLanguage]}:</span>
          <span>{email}</span>
        </div>
      </div>
    </div>
  )

  return (
    <Layout>
      <div id="careers-container">
        <TitleCard
          backgroundColor="black"
          fontColor="yellow"
          title={careerData.jobsAtMuni?.[currentLanguage]}
          icons={titleCardIcons}
        />
        <div id="careers-body">
          {openJobs.length === 0 ? null : (
            <span className="title">
              {careerData.currentOpenings?.[currentLanguage]}
            </span>
          )}
          <div id="careers-body-cards-container">
            {openJobs.length === 0 ? (
              <div className="no_open_jobs_container">
                <img src={noOpenPosition} alt="No open position" />
                <span>{careerData.noOpenPosition?.[currentLanguage]}</span>
              </div>
            ) : null}
          </div>

          <div id="send-cv-container">
            {!successStatus ? (
              <>
                <span className="cv_title margin_bottom_1">
                  {careerData.sendUsYourCV?.[currentLanguage]}
                </span>
                <span className="cv_slogan margin_bottom_1">
                  {careerData.newTalent?.[currentLanguage]}
                </span>
                <div className="career_form col-md-6 col-12">
                  <div className="row w-100">
                    <div className="col-12 col-md-6 padding_none">
                      <InputArea
                        currentValue={firstName}
                        changeText={setFirstName}
                        placeholder={contactData.name?.[currentLanguage]}
                        className="margin_right_1 mobile_size_no_margin"
                      />
                    </div>
                    <div className="col-12 col-md-6 padding_none">
                      <InputArea
                        changeText={setLastName}
                        currentValue={lastName}
                        placeholder={contactData.lastname?.[currentLanguage]}
                        className="margin_left_1 mobile_size_no_margin"
                      />
                    </div>
                    <InputArea
                      currentValue={email}
                      changeText={setEmail}
                      placeholder={careerData.email?.[currentLanguage]}
                      emailClass="email"
                      className="w-100"
                    />
                    <InputArea
                      currentValue={phoneNumber}
                      changeText={setPhoneNumber}
                      placeholder={contactData.phone?.[currentLanguage]}
                      className="w-100"
                    />
                    <InputArea
                      currentValue={resumeLink}
                      changeText={setResumeLink}
                      placeholder="CV Link"
                      emailClass="email"
                      className="w-100"
                    />
                  </div>
                  <button
                    onClick={sendCV}
                    className="website_btn signup_btn"
                    disabled={buttonDisableStatus}
                  >
                    <span>{buttonData.send?.[currentLanguage]}</span>
                  </button>
                </div>
              </>
            ) : (
              successfullySended
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Careers
